import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Meta from '../components/meta'

const LicenseAgreementPage = () => (
  <>
    <Meta
      title="License Agreement"
    />
    <Layout>
      <div className="typography-page limiter">
        <h2>END USER LICENSE AGREEMENT</h2>
        <p>
          IMPORTANT: THIS APPLICATION END USER LICENSE AGREEMENT (“EULA”) IS A LEGAL AGREEMENT BETWEEN YOU
          (EITHER AN INDIVIDUAL OR, IF PURCHASED OR OTHERWISE ACQUIRED BY OR FOR AN ENTITY, AN ENTITY) AND SCREENTRAY.
          READ IT CAREFULLY BEFORE COMPLETING THE INSTALLATION PROCESS AND USING THE APPLICATION. IT PROVIDES A LICENSE
          TO USE THE APPLICATION AND CONTAINS WARRANTY INFORMATION AND LIABILITY DISCLAIMERS. BY INSTALLING AND
          USING THE APPLICATION, YOU ARE CONFIRMING YOUR ACCEPTANCE OF THE APPLICATION AND AGREEING TO BECOME BOUND BY
          THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, THEN DO NOT INSTALL THE APPLICATION
          AND
          RETURN THE APPLICATION TO YOUR PLACE OF PURCHASE. THIS EULA SHALL APPLY ONLY TO THE APPLICATION SUPPLIED BY
          SCREENTRAY.
          HEREWITH REGARDLESS OF WHETHER OTHER APPLICATION IS REFERRED TO OR DESCRIBED HEREIN.
        </p>
        <h3>1. License</h3>
        <p>
          ScreenTray grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use
          the Application strictly in accordance with the terms of this Agreement.
        </p>
        <h3>2. License Restrictions</h3>
        <p>You agree not to, and you will not permit others to:</p>
        <ul>
          <li>
            license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially
            exploit the Application or make the Application available to any third party.
          </li>
          <li>
            modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the
            Application.
          </li>
          <li>
            remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of ScreenTray
            or
            its affiliates, partners, suppliers or the licensors of the Application.
          </li>
        </ul>
        <h3>3. Modifications to Application</h3>
        <p>
          ScreenTray reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or
          any service to which it connects, with or without notice and without liability to you.
        </p>
        <h3>4. Updates</h3>
        <p>
          ScreenTray may from time to time provide enhancements or improvements to the features/functionality of the
          Application,
          which may include patches, bug fixes, updates, upgrades and other modifications (“Updates”).
        </p>
        <p>
          Updates may modify or delete certain features and/or functionalities of the Application. You agree that
          ScreenTray
          has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features
          and/or
          functionalities of the Application to you.
        </p>
        <p>
          You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and
          (ii)
          subject to the terms and conditions of this Agreement.
        </p>
        <h3>5. Ownership</h3>
        <p>
          The foregoing license gives you limited license to use the application. ScreenTray and its suppliers retain all
          rights,
          title and interest, including all copyright and intellectual property rights, in and to, the application
          (as an independent work and as an underlying work serving as a basis for any application you may develop),
          and all copies thereof. All rights not specifically granted in this EULA, including Federal and
          International Copyrights, are reserved by ScreenTray and its suppliers.
        </p>
        <h3>6. Third-Party Services</h3>
        <p>
          The application may contain third party application which requires notices and/or additional terms and
          conditions.
          By accepting this EULA, you are also accepting the additional terms and conditions of the third party
          application.
        </p>
        <h3>7. Privacy Policy</h3>
        <p>
          ScreenTray collects, stores, maintains, and shares information about you in accordance with its Privacy Policy,
          which is available at <Link to="/privacy-policy/">Privacy Policy</Link>. By accepting this Agreement,
          you acknowledge that you hereby agree and consent to the terms and conditions of our Privacy Policy.
        </p>
        <h3>8. Term and Termination</h3>
        <p>This Agreement shall remain in effect until terminated by you or ScreenTray.</p>
        <p>
          ScreenTray may, in its sole discretion, at any time and for any or no reason, suspend or terminate this
          Agreement with or without prior notice.
        </p>
        <p>
          This Agreement will terminate immediately, without prior notice from ScreenTray, in the event that you fail to
          comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application
          and
          all copies thereof from your computer.
        </p>
        <p>
          Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of
          the Application from your computer.
        </p>
        <p>
          Termination of this Agreement will not limit any of ScreenTray’s rights or remedies at law or in equity in case
          of
          breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.
        </p>
        <h3>9. Indemnification</h3>
        <p>
          You agree to indemnify and hold ScreenTray and its parents, subsidiaries, affiliates, officers, employees,
          agents,
          partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys’ fees, due to
          or
          arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or
          (c)
          violation of any right of a third party.
        </p>
        <h3>10. Warranties Disclaimer</h3>
        <p>
          The Application is provided to you “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty
          of
          any kind. To the maximum extent permitted under applicable law, ScreenTray, on its own behalf and on behalf of
          its
          affiliates and its and their respective licensors and service providers, expressly disclaims all warranties,
          whether
          express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of
          merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out
          of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
          ScreenTray
          provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your
          requirements, achieve any intended results, be compatible or work with any other software, applications, systems
          or
          services, operate without interruption, meet any performance or reliability standards or be error free or that
          any
          errors or defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither ScreenTray nor any ScreenTray’s provider makes any representation or
          warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or
          the information, content, and materials or products included thereon; (ii) that the Application will be
          uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content
          provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from
          or on behalf of ScreenTray are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on
          the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not
          apply to you.
        </p>
        <h3>11. Limitation of Liability</h3>
        <p>
          Neither ScreenTray nor its suppliers shall be liable to you or any third party for any indirect, special,
          incidental,
          punitive or consequential damages (including, but not limited to, damages for the inability to use equipment or
          access data, loss of business, loss of profits, business interruption or the like), arising out of the use of,
          or
          inability to use, the application and based on any theory of liability including breach of contract, breach of
          warranty, tort (including negligence), product liability or otherwise, even if ScreenTray or its representatives
          have been advised of the possibility of such damages.
        </p>
        <h3>12. Severability</h3>
        <p>
          If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
          law and the remaining provisions will continue in full force and effect.
        </p>
        <h3>13. Amendments to this Agreement</h3>
        <p>
          ScreenTray reserves the right, at its sole discretion, to modify or replace this Agreement at any time.
          If a revision is material we will provide at least 30 days’ notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole discretion. By continuing to access or
          use our Application after any revisions become effective, you agree to be bound by the revised terms.
          If you do not agree to the new terms, you are no longer authorized to use the Application.
        </p>
        <h3>14. Governing Law</h3>
        <p>
          This License will be governed by the laws in force in Ukraine. You hereby consent to the non-exclusive
          jurisdiction and venue sitting in Ukraine to resolve any disputes arising under this EULA.
        </p>
        <h3>15. Term</h3>
        <p>
          By downloading and/or installing this application, the Licensor agrees to the terms of this EULA. This license
          is effective until terminated. Licensor has the right to terminate your License immediately if you fail to
          comply
          with any term of this License. “as is”. Licensor makes no Warranties, express or implied, arising from course of
          dealing or usage of trade, or statutory, as to any matter whatsoever. In particular, any and all warranties or
          merchantability, fitness for a particular purpose or non-infringement of third party rights are expressly
          excluded.
        </p>
        <h3>16. Contact Information</h3>
        <p>
          If you have any questions about this Agreement, please contact us at
          {' '}<a href="mailto:support@screentray.com">support@screentray.com</a>
        </p>
        <p>Last updated on Sep 13, 2018</p>
      </div>
    </Layout>
  </>
)

export default LicenseAgreementPage
